<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/icons8-dashboard-layout-96.png"
          class="h-20 ml-2 inline-block"
        />
        داشبورد
      </h2>
    </template>
    <template #default>
      <router-link
        :to="{ name: 'ImNotBot', params: { id: this.$store.state.user?.id } }"
        class="
          p-3
          bg-red-100
          border-red-200
          hover:bg-red-200
          text-red-900
          font-bold
          border
          rounded-md
          mb-5
          block
        "
        v-if="inGroups?.block"
      >
        <div class="flex items-center">
          <img src="@/assets/images/icons8-cyborg-96.png" />
          ربات شما را به عنوان ربات مخرب شناسایی کرده. برای خارج شدن از لیست کاربران مخرب
          اینجا کلیک کنید
        </div>
      </router-link>
      <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
        <router-link
          v-for="(item, index) in options"
          :key="index"
          :to="item.link"
          class="
            p-3
            bg-blueGray-100
            border-blueGray-200
            hover:bg-blueGray-200
            border
            rounded-md
            flex
            items-center
          "
        >
          <div class="text-lg font-bold w-full">{{ item.text }}</div>
          <img :src="item.image" class="mx-2 h-24" />
        </router-link>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";

export default {
  components: {
    BodySimple,
  },
  data() {
    return {
      firstLoading: true,
      error: true,
      inGroups: {},
    };
  },
  computed: {
    options() {
      return [
        {
          image: require("@/assets/images/3387286_payment_purse_shopping_wallet_icon.png"),
          text: "کیف پول",
          link: {
            name: "Wallet",
            params: { id: this.$store.state.user?.id || false },
          },
        },
        {
          image: require("@/assets/images/1054990_rocket_spacecraft_spaceship_icon.png"),
          text: "تبلیغات",
          link: {
            name: "Ad",
            params: { id: this.$store.state.user?.id || false },
          },
        },
        {
          image: require("@/assets/images/icons8-meeting-room-96.png"),
          text: "گروه های من",
          link: {
            name: "GroupOwner",
            params: { id: this.$store.state.user?.id || false },
          },
        },
        {
          image: require("@/assets/images/icons8-people-96.png"),
          text: "سایر گروه ها",
          link: {
            name: "GroupUser",
            params: { id: this.$store.state.user?.id || false },
          },
        },
        {
          image: require("@/assets/images/icons8-micro-96.png"),
          text: "کانال ها من",
          link: {
            name: "ChannelOwner",
            params: { id: this.$store.state.user?.id || false },
          },
        },
        {
          image: require("@/assets/images/headphone.png"),
          text: "سایر کانال ها",
          link: {
            name: "ChannelUser",
            params: { id: this.$store.state.user?.id || false },
          },
        },
      ];
    },
  },
  mounted() {
    let $this = this;
    $this.$axios
      .get("/api/get-user-info", {
        params: {
          id: $this.$store.state.user.id,
        },
      })
      .then(function (response) {
        // console.log(response);
        $this.inGroups = response.data.inGroups;
      })
      .catch(function (error) {
        // console.log(error);
      })
      .finally(function () {
        // always executed
        $this.firstLoading = false;
      });
  },
};
</script>
